import { useRouteLoaderData } from "@remix-run/react";

import type { loader } from "~/root";
import { BaseNav, Breadcrumb } from "./TopNav";

/**
 * Warning:
 * This is rendered in the ErrorBoundary, so it is possible for data to be undefined
 * If a loader has failed. Use `useRouteLoaderData` instead of `useLoaderData`
 */
export const ErrorNav = () => {
  const data = useRouteLoaderData<typeof loader>("root");
  return <BaseNav breadCrumbs={<Breadcrumb />} logoURL={data?.logoURL} />;
};
