import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import type { SentryMetaArgs } from "@sentry/remix";
import { ClerkApp, ClerkErrorBoundary } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { Progress, Toaster, TooltipProvider } from "@infinitenz/pyrite";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
  useRouteLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { MapProvider as MapLibreProvider } from "react-map-gl";
import { useSpinDelay } from "spin-delay";

import { ErrorNav } from "~/modules/layout/ErrorNav";
import { MAP_IMAGE_LINKS } from "~/modules/map/util/load-map-images";
import { ApeironErrorBoundary } from "~/modules/root/component/ApeironErrorBoundary";
import { HeadTags } from "~/modules/root/component/HeadTags";
// ## styles
import "@fontsource/inter";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "maplibre-gl/dist/maplibre-gl.css";
import "remixicon/fonts/remixicon.css";

import { getApeironLogo } from "./modules/util/get-apeiron-logo";

import "./styles/globals.css";
import "./styles/tailwind.css";

export const meta = ({ data }: SentryMetaArgs<MetaFunction<typeof loader>>) => {
  return [
    { title: "Apeiron" },
    { name: "description", content: "Apeiron" },
    {
      name: "sentry-trace",
      content: data?.sentryTrace,
    },
    {
      name: "baggage",
      content: data?.sentryBaggage,
    },
  ];
};

/**
 * We don't need a Policy here as this can be accessed by anyone
 *
 * Login route, Signup route
 *
 * @param args
 */
export const loader = (args: LoaderFunctionArgs) => {
  return rootAuthLoader(args, () => {
    const logoURL = getApeironLogo();
    const baseURL = new URL(args.request.url).origin;
    return { ...args.context, logoURL, baseURL };
  });
};
/**
 * Note: Fonts loaded by client on document load, they are not bundled
 */
export const links: LinksFunction = () => [
  {
    rel: "preload",
    href: "/logo.svg",
    as: "image",
    type: "image/svg+xml",
  },
  {
    rel: "icon",
    href: "/favicon.ico",
    type: "image/x-icon",
    as: "image",
  },
  {
    rel: "preload",
    href: "/fonts/Inter-Bold.woff2",
    type: "font/woff2",
    as: "font",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "/fonts/Inter-SemiBold.woff2",
    type: "font/woff2",
    as: "font",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "/fonts/Inter-Medium.woff2",
    type: "font/woff2",
    as: "font",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "/fonts/Inter-Regular.woff2",
    type: "font/woff2",
    as: "font",
    crossOrigin: "anonymous",
  },
  {
    rel: "preload",
    href: "/fonts/Inter-Light.woff2",
    type: "font/woff2",
    as: "font",
    crossOrigin: "anonymous",
  },
  ...MAP_IMAGE_LINKS,
];

function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <Meta />
        <Links />
        <HeadTags {...data} />
      </head>
      <body className="overflow-hidden">
        <OutletWithProviders />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function OutletWithProviders() {
  const navigation = useNavigation();
  const busy = navigation.state !== "idle";
  const delayedPending = useSpinDelay(busy, {
    delay: 600,
    minDuration: 400,
  });

  return (
    <MapLibreProvider>
      <Toaster />
      <TooltipProvider>
        <Progress
          className={`${
            delayedPending ? "h-1" : "h-0"
          } w-full" absolute left-0 top-0 z-50`}
          value={delayedPending ? 75 : 0}
          max={100}
        />
        <Outlet />
      </TooltipProvider>
    </MapLibreProvider>
  );
}

const DefaultBoundary = () => {
  const data = useRouteLoaderData<typeof loader>("root");
  return (
    <html>
      <head>
        <title>Apeiron - Oh no!</title>
        <Meta />
        <Links />
        {data && <HeadTags {...data} />}
      </head>
      <body className="flex h-screen w-screen flex-col">
        <ErrorNav />
        <div className="flex flex-grow overflow-hidden">
          <ApeironErrorBoundary />
        </div>
        <Scripts />
      </body>
    </html>
  );
};

export const ErrorBoundary = ClerkErrorBoundary(DefaultBoundary);

export default ClerkApp(withSentry(App));
